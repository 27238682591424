import {
  GuildSignupMultiShowLayout,
  GuildSignupSingleShowLayout,
  GuildSignupVideoLayout,
} from '@/services/GuildSignupPageService/GuildSignupPageTypes'
import { isHasTypeName } from '@/services/RenderService'
import {
  GuildSignupExplainerLayout,
  GuildSignupScrollingLayout,
  GuildSignupSimpleLayout,
} from '@/types/codegen-contentful'

export function isGuildSignupVideoLayout(layout: unknown): layout is GuildSignupVideoLayout {
  return isHasTypeName(layout) && layout.__typename === 'GuildSignupVideoLayout'
}

export function isGuildSignupMultiShowLayout(layout: unknown): layout is GuildSignupMultiShowLayout {
  return isHasTypeName(layout) && layout.__typename === 'GuildSignupMultiShowLayout'
}

export function isGuildSignupSimpleLayout(layout: unknown): layout is GuildSignupSimpleLayout {
  return isHasTypeName(layout) && layout.__typename === 'GuildSignupSimpleLayout'
}

export function isGuildSignupSingleShowLayout(layout: unknown): layout is GuildSignupSingleShowLayout {
  return isHasTypeName(layout) && layout.__typename === 'GuildSignupSingleShowLayout'
}

export function isGuildSignupScrollingLayout(layout: unknown): layout is GuildSignupScrollingLayout {
  return isHasTypeName(layout) && layout.__typename === 'GuildSignupScrollingLayout'
}

export function isGuildExplainerLayout(layout: unknown): layout is GuildSignupExplainerLayout {
  return isHasTypeName(layout) && layout.__typename === 'GuildSignupExplainerLayout'
}
