import { useEffect, useState } from 'react'
import { Durations } from '@/constants/durations'

export interface TimeLeft {
  days: number
  hours: number
  minutes: number
  seconds: number
}

interface UseTimeLeftProps {
  targetDate: Date
}
export const useTimeLeft = ({ targetDate }: UseTimeLeftProps) => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft(targetDate))

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate))
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [targetDate])

  return timeLeft
}

export const calculateTimeLeft = (targetDate: Date, startDate = new Date()): TimeLeft => {
  const difference = targetDate.getTime() - startDate.getTime()

  if (difference <= 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }

  const days = Math.floor(difference / Durations.ONE_DAY_IN_MILLISECONDS)
  const hours = Math.floor((difference % Durations.ONE_DAY_IN_MILLISECONDS) / Durations.ONE_HOUR_IN_MILLISECONDS)
  const minutes = Math.floor((difference % Durations.ONE_HOUR_IN_MILLISECONDS) / Durations.ONE_MINUTE_IN_MILLISECONDS)
  const seconds = Math.floor((difference % Durations.ONE_MINUTE_IN_MILLISECONDS) / Durations.ONE_SECOND_IN_MILLISECONDS)

  return {
    days,
    hours,
    minutes,
    seconds,
  }
}
