import React from 'react'
import { OneColorIconProps } from '../utils'

export const GuildCopperIcon: React.FC<OneColorIconProps> = ({ size = 24, className }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0835 2.5H2.91683C2.45659 2.5 2.0835 2.87145 2.0835 3.33169V9.90741C2.0835 13.6111 5.05225 17.7778 10.0002 19.1667C14.9481 17.7778 17.9168 13.6111 17.9168 9.90741V3.33141C17.9168 2.87117 17.5437 2.5 17.0835 2.5Z"
        fill="url(#paint0_linear_2099_10428)"
      />
      <path
        d="M10.0081 9.32254H12.8803C12.9353 9.32682 12.9902 9.35254 13.0155 9.3954C13.0535 9.43397 13.0789 9.48969 13.0789 9.5497V13.3171C13.0789 13.3471 13.0704 13.3814 13.0577 13.4114C13.0451 13.4414 13.024 13.4672 12.9986 13.4886C12.12 13.9515 11.1401 14.1829 10.1475 14.1658C7.86656 14.1101 6.04183 12.3142 6.04183 9.99973C6.04183 7.68955 7.86656 5.80368 10.1475 5.83369C11.1105 5.86369 12.1749 6.29229 12.9564 7.01235C12.9817 7.0295 13.0028 7.05521 13.0155 7.08093C13.0324 7.10664 13.0408 7.13665 13.0408 7.16665C13.0408 7.19665 13.0366 7.22665 13.024 7.25666C13.0113 7.28237 12.9944 7.30809 12.9733 7.32952L12.082 8.27674C12.0356 8.30674 11.9806 8.32388 11.9257 8.32388C11.8708 8.32388 11.8159 8.30674 11.7695 8.27674C11.3344 7.84385 10.8233 7.68526 10.27 7.6724C8.8803 7.6424 8.01862 8.72249 8.01862 9.98687C8.01862 11.2641 8.8803 12.2971 10.2826 12.3271C10.629 12.3356 10.9711 12.2842 11.3006 12.1685V10.9941C11.0472 10.9812 10.2278 10.9812 9.99965 10.9812C9.97009 10.9812 9.94472 10.977 9.91516 10.9684C9.88981 10.9555 9.86447 10.9427 9.84335 10.9212C9.82223 10.8998 9.80533 10.8741 9.79266 10.8484C9.77999 10.8227 9.77577 10.7927 9.77577 10.7627V9.53684C9.77577 9.50684 9.77999 9.47683 9.79266 9.45112C9.80533 9.4254 9.82223 9.39969 9.84335 9.37825C9.86447 9.35682 9.88981 9.33968 9.91938 9.33111C9.94895 9.32254 9.97852 9.31825 10.0081 9.32254Z"
        fill="#4A2B27"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2099_10428"
          x1="3.49037"
          y1="2.44444"
          x2="8.04304"
          y2="20.8576"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDD7C6" />
          <stop offset="0.138791" stopColor="#D6A177" />
          <stop offset="0.40615" stopColor="#FEDEC6" />
          <stop offset="0.622099" stopColor="#D48D59" />
          <stop offset="0.887729" stopColor="#A66141" />
          <stop offset="0.981481" stopColor="#612B12" />
        </linearGradient>
      </defs>
    </svg>
  )
}
