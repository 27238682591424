import { GetSalesQuery } from '@/types/codegen-contentful'
import { logger } from '@/utils/logging'
import { ContentfulClientOptions, getContentfulClient } from '../ApolloClient'
import { GET_SALES_QUERY } from './queries'
import { getActiveSales } from './shared'
import { ValidSale } from './types'

export async function getActiveSalesFromContentful(opts: ContentfulClientOptions): Promise<ValidSale[]> {
  const result = await fetchSales(opts)
  const activeSales = getActiveSales(result)
  return activeSales
}

async function fetchSales(opts: ContentfulClientOptions): Promise<GetSalesQuery | undefined> {
  const contentfulClient = getContentfulClient(opts)
  const result = await contentfulClient.query<GetSalesQuery>({
    query: GET_SALES_QUERY,
    variables: { locale: opts.locale, preview: opts.preview },
    fetchPolicy: 'network-only',
  })

  if (result.error) {
    logger().error('An error occurred while attempting to fetch Sale objects from Contentful!', { opts }, result.error)
    return
  }

  return result.data
}
