import React from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { When } from 'react-if'
import { InView } from 'react-intersection-observer'
import { CaptionLG, HeadingXS } from '@/atoms/Text'
import {
  ANGEL_GUILD_BASIC_MONTHLY_OFFER_ID,
  ANGEL_GUILD_BASIC_YEARLY_OFFER_ID,
  ANGEL_GUILD_PREMIUM_MONTHLY_OFFER_ID,
  ANGEL_GUILD_PREMIUM_YEARLY_OFFER_ID,
} from '@/constants/offer-ids'
import { useEcommerceEvents } from '@/utils/analytics/ecommerce-events'
import { useTranslate } from '@/utils/translate/translate-client'
import { GuildPlanBoxTiers } from '@/views/GuildJoinView/GuildPlanBoxTiers'

interface Props {
  className?: string
  containerRef?: React.Ref<HTMLDivElement>
  defaultPlan?: 'monthly' | 'annual'
  darkMode?: boolean
  signupLayout?: boolean
  hideBasic?: boolean
  hideLabels?: boolean
  hideYearly?: boolean
}

export const GuildPlanSelectionTiers: React.FC<Props> = ({
  className,
  containerRef,
  darkMode = false,
  signupLayout = false,
  hideBasic = false,
  hideLabels = false,
  hideYearly = false,
}) => {
  const { t } = useTranslate('guild')
  const { query } = useRouter()
  const { trackProductViewed } = useEcommerceEvents()

  return (
    <InView
      triggerOnce
      onChange={(inView) => {
        if (!inView) return
        const projectSlug = query.slug && String(query.slug)

        trackProductViewed({
          category: 'guild',
          funnel: 'guild',
          name: 'Angel Guild Plans',
          price: 0,
          currency: 'USD',
          quantity: 1,
          product_id: 'Angel Guild Plans',
          sku: 'Angel Guild Plans',
          projectSlug,
          variant: 'basic monthly, basic yearly, premium monthly, premium yearly',
        })
      }}
      className="flex flex-col items-center"
    >
      <When condition={!hideLabels}>
        {!signupLayout && (
          <CaptionLG
            color={darkMode ? 'white' : 'core-gray-950'}
            weight={signupLayout ? 'semibold' : 'bold'}
            className={classNames('pb-4', { '!photon-title-md': signupLayout })}
          >
            {t('selectMembershipPlans', 'Select membership plans')}.
          </CaptionLG>
        )}
      </When>
      <HeadingXS
        color={darkMode ? 'white' : 'core-gray-950'}
        weight={signupLayout ? 'semibold' : 'bold'}
        className={classNames('pb-4', { '!photon-title-md': signupLayout })}
      >
        {t('premiumMembershipV2', 'Premium')}
      </HeadingXS>
      <div
        ref={containerRef}
        className={classNames(
          'mx-4 grid grid-cols-1 gap-8 *:col-span-3 lg:grid-cols-2 lg:*:col-span-1 pb-8',
          className,
        )}
      >
        <PremiumPlan orderClass="lg:order-2" planInterval={'monthly'} title={t('monthly', 'Monthly')} />
        {!hideYearly && <PremiumPlan orderClass="lg:order-2" planInterval={'annual'} title={t('yearly', 'Yearly')} />}
      </div>
      <When condition={!hideBasic}>
        <HeadingXS
          color={darkMode ? 'white' : 'core-gray-950'}
          weight={signupLayout ? 'semibold' : 'bold'}
          className={classNames('pb-4', { '!photon-title-md': signupLayout })}
        >
          {t('basicMembershipV2', 'Basic')}
        </HeadingXS>
        <div
          ref={containerRef}
          className={classNames(
            'mx-4 grid grid-cols-1 gap-8 *:col-span-3 lg:grid-cols-2 lg:*:col-span-1 pb-8',
            className,
          )}
        >
          <BasicPlan orderClass="lg:order-0" planInterval={'monthly'} title={t('monthly', 'Monthly')} />
          {!hideYearly && <BasicPlan orderClass="lg:order-0" planInterval={'annual'} title={t('yearly', 'Yearly')} />}
        </div>
      </When>
    </InView>
  )
}

interface Plan {
  className?: string
  orderClass?: string
  planInterval?: 'annual' | 'monthly'
  title: string
  isQuote?: boolean
}

export const PremiumPlan: React.FC<Plan> = ({ className, orderClass, planInterval, title, isQuote }) => {
  return (
    <GuildPlanBoxTiers
      className={className}
      offerId={ANGEL_GUILD_PREMIUM_YEARLY_OFFER_ID}
      monthlyOfferId={ANGEL_GUILD_PREMIUM_MONTHLY_OFFER_ID}
      orderClass={orderClass}
      price={17900}
      monthlyPrice={2000}
      tier="premium"
      title={title}
      planInterval={planInterval}
      punchy
      isQuote={isQuote}
    />
  )
}

export const BasicPlan: React.FC<Plan> = ({ className, orderClass, planInterval, title, isQuote }) => {
  return (
    <GuildPlanBoxTiers
      className={className}
      offerId={ANGEL_GUILD_BASIC_YEARLY_OFFER_ID}
      monthlyOfferId={ANGEL_GUILD_BASIC_MONTHLY_OFFER_ID}
      price={11900}
      monthlyPrice={1200}
      orderClass={orderClass}
      tier="basic"
      planInterval={planInterval}
      title={title}
      isQuote={isQuote}
    />
  )
}
