import React, { MouseEventHandler, useCallback, useState } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { getVariantClasses } from '@/atoms/Button/ButtonUtil'
import { useExperimentContext } from '@/experimentation'
import { handleSignUpFlowExperiment } from '@/services/Auth/utils'
import { getPriceForCurrency } from '@/services/PriceFormatter'
import { useUser } from '@/services/UserService'
import { MoneyCurrencyCode } from '@/types/codegen-federation'
import { useSafeTrack } from '@/utils/analytics'
import { useEcommerceEvents } from '@/utils/analytics/ecommerce-events'
import { AuthStep, SignUpAuthForm } from '@/views/GuildSignUpAuthView/SignUpAuthForm'
import { useGuildSignupContext } from '@/views/GuildSignupView/GuildSignupContext'
import { Modal } from '../Modal'

interface Props {
  children: React.ReactNode
  currency?: string
  offerId: string
  price: number
  promoCode?: string | null
  planInterval: 'annual' | 'monthly'
  className?: string
}

export const GuildSignupButtonNew: React.FC<Props> = ({
  children,
  currency = 'USD',
  offerId,
  price,
  promoCode,
  planInterval,
  className,
}) => {
  const [step, setStep] = useState<AuthStep>('email')
  const [showAuthModal, setShowAuthModal] = useState(false)
  const track = useSafeTrack()
  const { trackProductAdded } = useEcommerceEvents()
  const { slug } = useGuildSignupContext()
  const { getFeatureValue } = useExperimentContext()
  const router = useRouter()
  const { isLoggedIn } = useUser()

  const handleGuildSignupStarted: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      // TODO: deprecated - remove after release of trackProductAdded and marketing's (Kylo's) blessing
      track('Guild Checkout Started', {
        slug: slug,
        planId: offerId,
      })

      const formattedPrice = getPriceForCurrency(price, currency)
      trackProductAdded({
        category: 'guild',
        funnel: 'guild',
        name: `Angel Guild ${planInterval} - $${formattedPrice}`,
        price: formattedPrice,
        currency: currency as MoneyCurrencyCode,
        quantity: 1,
        product_id: offerId,
        sku: offerId,
        projectSlug: slug,
      })
      if (isLoggedIn) return

      const signUpFlowFeatureValue = getFeatureValue('new-guild-sign-up-flow-v2', 'control')
      const isControl = signUpFlowFeatureValue === 'control'
      const url = `/guild/${isControl ? 'checkout' : 'signup'}/${offerId}${promoCode ? `?promoCode=${promoCode}` : ''}`
      handleSignUpFlowExperiment(e, url, router, signUpFlowFeatureValue, () => setShowAuthModal(true))
    },
    [
      currency,
      track,
      slug,
      offerId,
      price,
      planInterval,
      trackProductAdded,
      getFeatureValue,
      router,
      promoCode,
      isLoggedIn,
    ],
  )

  return (
    <>
      <a
        className={classNames(
          'btn',
          getVariantClasses({ variant: 'gray-900' }),
          'w-full rounded-[10px] px-5 py-[11px]',
          className,
        )}
        href={`/guild/checkout/${offerId}${promoCode ? `?promoCode=${promoCode}` : ''}`}
        onClick={handleGuildSignupStarted}
      >
        {children}
      </a>
      <Modal onClose={() => setShowAuthModal(false)} isOpen={showAuthModal}>
        <SignUpAuthForm
          isModal
          offerId={offerId}
          userEmail=""
          onEmailSubmit={() => setStep('create-account')}
          step={step}
        />
      </Modal>
    </>
  )
}
