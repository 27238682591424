import { ApolloClient } from '@apollo/client'
import Bluebird from 'bluebird'
import type { Stripe } from 'stripe'
import { logger } from '@/utils/logging'
import { isDefined } from '@/utils/types'
import { ContentfulClientOptions } from '../ApolloClient'
import { getActiveSalesFromContentful, ValidSale } from '../ContentfulSaleService'
import { getOfferById, Offer } from '../PifService'
import { formatPrice } from '../PriceFormatter'
import { findPromotionCodeByCustomerFacingCode } from '../StripeService/StripeCouponService'

export interface ValidatedPromotion {
  offer: Offer
  promotionCode: Stripe.PromotionCode
}

export interface GuildSale extends ValidatedSale {
  category: 'guild'
}

export interface ValidatedSale extends Omit<ValidSale, 'promotionsCollection'> {
  promotions: ValidatedPromotion[]
}

interface GetValidatedActiveSalesArgs {
  opts: ContentfulClientOptions
  federationClient: ApolloClient<object>
}

export async function getGuildSale(args: GetValidatedActiveSalesArgs): Promise<GuildSale | null> {
  const sales = await getValidatedActiveSales(args)
  const guildSales = sales.filter(isGuildSale)
  const guildSale = guildSales[0]

  if (guildSales.length === 0) {
    logger().debug('There are currently no guild sales. Nothing will be returned.')
  } else if (guildSales.length === 1) {
    logger().info('Successfully fetched an active guild sale.', { guildSale })
  } else {
    logger().error('There are currently multiple active guild sales! Only the first will be used.', {
      guildSale,
      guildSales,
    })
  }

  return guildSale || null
}

function isGuildSale(sale: ValidatedSale): sale is GuildSale {
  return sale.category === 'guild'
}

async function getValidatedActiveSales({
  opts,
  federationClient,
}: GetValidatedActiveSalesArgs): Promise<ValidatedSale[]> {
  const activeSales = await getActiveSalesFromContentful(opts)
  const results = await Bluebird.map(
    activeSales,
    (sale) => transformContentfulSaleToValidatedSale(sale, federationClient),
    { concurrency: 3 },
  )
  const validatedSales = results.filter(isDefined)
  if (validatedSales.length)
    logger().info('Successfully fetched and validated active sale objects.', { validatedSales })
  return validatedSales
}

export async function transformContentfulSaleToValidatedSale(
  sale: ValidSale,
  federationClient: ApolloClient<object>,
): Promise<ValidatedSale | undefined> {
  const promotions = sale.promotionsCollection?.items || []
  if (promotions.length === 0) {
    logger().error('The sale object does not have any promotions! This sale will be disregarded.', { sale })
    return
  }

  const validPromotions: ValidatedPromotion[] = []
  for (const promotion of promotions) {
    const maybeValid = await getValidPromotion(sale, promotion, federationClient)
    if (maybeValid) validPromotions.push(maybeValid)
  }

  if (validPromotions.length === 0) {
    logger().error('None of the promotion objects associated with the sale are valid! This sale will be disregarded.', {
      sale,
    })
    return
  }

  const validatedSale: ValidatedSale = {
    ...sale,
    promotions: validPromotions,
  }
  logger().info('This sale has been validated.', { sale, validatedSale })
  return validatedSale
}

async function getValidPromotion(
  sale: ValidSale,
  promotion: NonNullable<ValidSale['promotionsCollection']>['items'][number],
  federationClient: ApolloClient<object>,
): Promise<ValidatedPromotion | undefined> {
  if (!promotion?.bimId) {
    logger().error(
      'This promotion is missing a bimId! A product for the promotion cannot be looked up! This promotion will be disregarded.',
      { sale, promotion },
    )
    return
  }

  const offer = await getOfferById({ offerId: promotion.bimId }, federationClient)
  if (!offer) {
    logger().error(
      'A Promotion in Contentful associated with a sale is linked to a bim product id that does not exist! This promotion will be disregarded.',
      { sale, promotion, bimId: promotion.bimId },
    )
    return
  }

  if (!promotion?.promoCode) {
    logger().error(
      'This promotion is missing a promoCode! The Stripe PromotionCode cannot be looked up! This promotion will be disregarded.',
      { sale, promotion },
    )
    return
  }

  const promotionCode = await findPromotionCodeByCustomerFacingCode(promotion.promoCode)

  if (!promotionCode) {
    logger().error(
      'Failed to fetch a Stripe Promotion Code for a promotion defined in Contentful associated with a sale. This promotion will be disregarded.',
      { sale, promotion, promoCode: promotion.promoCode },
    )
    return
  }
  if (!promotionCode.active) {
    logger().error(
      'A Promotion in Contentful associated with a sale is linked to a Promotion Code in Stripe that is not active! This promotion will be disregarded.',
      { sale, promotion, promoCode: promotion.promoCode },
    )
    return
  }

  return { offer, promotionCode }
}

export function getPromotionalPrice(promotion: ValidatedPromotion): number | null {
  if (!promotion) return null
  const originalPrice = promotion.offer.price
  let price = originalPrice

  if (promotion.promotionCode.coupon.amount_off && originalPrice - promotion.promotionCode.coupon.amount_off > 0) {
    price = originalPrice - promotion.promotionCode.coupon.amount_off
  } else if (promotion.promotionCode.coupon.percent_off) {
    const discountAmount = (originalPrice * promotion.promotionCode.coupon.percent_off) / 100
    price = originalPrice - discountAmount
  }

  return price
}

export function formatPromotionPriceDisplay(promotion: ValidatedPromotion, locale: string): string | null {
  const price = getPromotionalPrice(promotion)
  if (price === null) return null

  return formatPrice(price, {
    locale,
    currency: promotion.offer.currency,
    includeDecimals: true,
  })
}
