import React from 'react'
import { Case, Default, Else, If, Switch, Then, When } from 'react-if'
import type { Stripe } from 'stripe'
import { CloseIcon } from '@/atoms/Icons/CloseIcon'
import { AsP, CaptionSM, TitleMD } from '@/atoms/Text'
import { formatPrice } from '@/services/PriceFormatter'
import { formatPromotionPriceDisplay, getPromotionalPrice, ValidatedPromotion } from '@/services/SaleService'
import { GuildPlanSelectionNew } from '@/utils/GuildUtil/GuildUtil'
import { useLocale } from '@/utils/LocaleUtil'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { useTimeLeft } from '@/utils/useTimeLeft/useTimeLeft'
import { useGuildSignupContext } from '@/views/GuildSignupView/GuildSignupContext'

interface Props extends GuildPlanSelectionNew {
  className?: string
  offerId: string
  planInterval: 'annual' | 'monthly'
}

export const GuildPricingLabel: React.FC<Props> = ({
  className,
  currency = 'USD',
  price,
  monthlyPrice,
  tier,
  planInterval,
  offerId,
}) => {
  const { locale } = useLocale()
  const { t } = useTranslate('guild')
  const { sale } = useGuildSignupContext()
  const promotion = sale?.promotions.find((p) => p.offer.id === offerId) as ValidatedPromotion
  const isPromoApplicable = Boolean(promotion)

  const planPrice = planInterval === 'annual' ? price : monthlyPrice

  const promotionMonths = promotion?.promotionCode?.coupon?.duration_in_months
  const promotionalPrice = getPromotionalPrice(promotion)
  const promotionalPricePerMonth = formatPrice((promotionalPrice as number) / 12, { locale, currency: 'USD' })

  return (
    <div className={className}>
      <TitleMD as={AsP} weight="bold">
        <Switch>
          <Case condition={isPromoApplicable}>
            {!!promotion && (
              <Switch>
                <Case condition={planInterval === 'annual' && tier === 'premium'}>
                  <ExtraSavingsLabel
                    fullPrice={24000}
                    normalPrice={planPrice}
                    promoPrice={formatPromotionPriceDisplay(promotion, locale)}
                  />
                </Case>
                <Case condition={planInterval === 'annual' && tier === 'basic'}>
                  <ExtraSavingsLabel
                    fullPrice={14400}
                    normalPrice={planPrice}
                    promoPrice={formatPromotionPriceDisplay(promotion, locale)}
                  />
                </Case>
                <Default>
                  <span className="text-core-gray-600 line-through decoration-warm-01 decoration-2">
                    {formatPrice(planPrice, { locale, includeDecimals: false, currency: 'USD' })}
                  </span>
                  &nbsp;
                  <span className="text-black">{formatPromotionPriceDisplay(promotion, locale)}</span>
                </Default>
              </Switch>
            )}
          </Case>
          <Case condition={planInterval === 'annual'}>
            <>
              <If condition={Boolean(promotion && tier === 'premium')}>
                <Then>
                  <span className="text-core-gray-600 line-through decoration-warm-01 decoration-2">
                    {formatPrice(24000, { locale, includeDecimals: false, currency: 'USD' })}
                  </span>
                  &nbsp;
                  <span className="text-black">{!!promotion && formatPromotionPriceDisplay(promotion, locale)}</span>
                </Then>
                <Else>
                  <When condition={tier === 'premium'}>
                    <>
                      <span className="text-core-gray-600 line-through decoration-warm-01 decoration-2">
                        {formatPrice(24000, { locale, includeDecimals: false, currency: 'USD' })}
                      </span>
                      &nbsp;
                      <span className="text-black">
                        {formatPrice(price, { locale, includeDecimals: false, currency })}
                      </span>
                    </>
                  </When>
                  <When condition={tier === 'basic'}>
                    <>
                      <span className="text-core-gray-600 line-through decoration-warm-01 decoration-2">
                        {formatPrice(monthlyPrice * 12, { locale, includeDecimals: false, currency: 'USD' })}
                      </span>
                      &nbsp;
                      <span className="text-black">
                        {formatPrice(price, { locale, includeDecimals: false, currency })}
                      </span>
                    </>
                  </When>
                </Else>
              </If>
            </>
          </Case>
          <Default>
            <>{formatPrice(monthlyPrice, { locale, includeDecimals: false, currency })}</>
          </Default>
        </Switch>
        <span className="text-sm font-semibold lowercase text-core-gray-700">
          /{planInterval === 'annual' ? t('year', 'Year') : t('month', 'Month')}
        </span>
      </TitleMD>
      <div className="mt-1">
        <If condition={isPromoApplicable}>
          <Then>
            <Switch>
              <Case condition={promotionalPricePerMonth && planInterval === 'annual'}>
                <CaptionSM color="warm-01" className="mb-3" weight="bold">
                  <Translate t={t} i18nKey="promotionalPricePerMonthV1" values={{ promotionalPricePerMonth }}>
                    {{ promotionalPricePerMonth }}/month for the first year, billed annually
                  </Translate>
                </CaptionSM>
              </Case>
              <Case condition={isPromotionOneTime(promotion?.promotionCode)}>
                <CaptionSM color="warm-01" className="mb-3" weight="bold">
                  <If condition={planInterval === 'monthly'}>
                    <Then>
                      <Translate t={t} i18nKey="firstMonthSavingsBilledMonthlyV2">
                        For the first month, billed monthly
                      </Translate>
                    </Then>
                    <Else>
                      <Translate t={t} i18nKey="oneTimeSavingsBilledYearlyV2">
                        For the first year, billed annually
                      </Translate>
                    </Else>
                  </If>
                </CaptionSM>
              </Case>
              <Case condition={isPromotionNTimes(promotion?.promotionCode) && planInterval === 'monthly'}>
                <CaptionSM color="warm-01" className="mb-3" weight="bold">
                  <Translate t={t} i18nKey="firstNMonthsSavingsBilledMonthlyV2" values={{ promotionMonths }}>
                    For the first {{ promotionMonths }} months, billed monthly
                  </Translate>
                </CaptionSM>
              </Case>
              <Case condition={sale?.endDate}>
                <CountdownToSave
                  endDate={new Date(sale?.endDate as string)}
                  isAlreadyReduced={planInterval === 'annual'}
                />
              </Case>
            </Switch>
          </Then>
          <Else>
            <CaptionSM color="core-gray-700" className="mb-3">
              <Switch>
                <Case condition={tier === 'premium' && planInterval === 'annual' && sale?.abbreviatedPitch}>
                  {sale?.abbreviatedPitch}
                </Case>
                <Case condition={tier === 'basic' && planInterval === 'annual'}>
                  {t('save16PercentAnnually', 'Save 16% annually')}
                </Case>
                <Case condition={tier === 'basic' && planInterval === 'monthly'}>
                  {t('billedMonthly', 'Billed monthly')}
                </Case>
                <Case condition={tier === 'premium' && planInterval === 'annual'}>
                  {t('save25PercentAnnually', 'Save 25% annually')}
                </Case>
                <Case condition={tier === 'premium' && planInterval === 'monthly'}>
                  {t('billedMonthly', 'Billed monthly')}
                </Case>
              </Switch>
            </CaptionSM>
          </Else>
        </If>
      </div>
    </div>
  )
}

interface CountdownToSaveProps {
  endDate: Date
  isAlreadyReduced?: boolean
}
const CountdownToSave: React.FC<CountdownToSaveProps> = ({ endDate, isAlreadyReduced }) => {
  const { t } = useTranslate('join-the-guild')
  const { days, hours, minutes } = useTimeLeft({ targetDate: endDate })
  let display

  if (isAlreadyReduced) {
    if (days > 0) {
      display = t('daysToSaveAlreadyReduced', {
        days,
        count: days,
        defaultValue: '{{days}} day left to save on already reduced price!',
        defaultValue_other: '{{days}} days left to save on already reduced price!',
      })
    } else if (hours > 0) {
      display = t('hoursToSaveAlreadyReduced', {
        hours,
        count: hours,
        defaultValue: '{{hours}} hour left to save on already reduced price!',
        defaultValue_other: '{{hours}} hours left to save on already reduced price!',
      })
    } else if (minutes > 0) {
      display = t('minutesToSaveAlreadyReduced', {
        minutes,
        count: minutes,
        defaultValue: '{{minutes}} minute left to save on already reduced price!',
        defaultValue_other: '{{minutes}} minutes left to save on already reduced price!',
      })
    } else {
      display = t('lastChanceToSaveAlreadyReduced', 'Last chance to save on already reduced price!')
    }
  } else {
    if (days > 0) {
      display = t('daysToSave', {
        days,
        count: days,
        defaultValue: '{{days}} day left to save',
        defaultValue_other: '{{days}} days left to save',
      })
    } else if (hours > 0) {
      display = t('hoursToSave', {
        hours,
        count: hours,
        defaultValue: '{{hours}} hour left to save',
        defaultValue_other: '{{hours}} hours left to save',
      })
    } else if (minutes > 0) {
      display = t('minutesToSave', {
        minutes,
        count: minutes,
        defaultValue: '{{minutes}} minute left to save',
        defaultValue_other: '{{minutes}} minutes left to save',
      })
    } else {
      display = t('lastChanceToSave', 'Last chance to save!')
    }
  }

  return (
    <CaptionSM color="warm-01" className="mb-3" weight="bold">
      {display}
    </CaptionSM>
  )
}

function ExtraSavingsLabel({
  fullPrice,
  normalPrice,
  promoPrice,
}: {
  fullPrice: number
  normalPrice: number
  promoPrice: string | null
}) {
  const { locale } = useLocale()
  return (
    <>
      <span className="relative text-core-gray-600">
        <CloseIcon
          size={40}
          color="warm-01"
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rotate-[12deg]"
        />
        {formatPrice(fullPrice, { locale, includeDecimals: false, currency: 'USD' })}
      </span>
      &nbsp;
      <span className="relative text-core-gray-600">
        <CloseIcon
          size={40}
          color="warm-01"
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rotate-[12deg]"
        />
        {formatPrice(normalPrice, { locale, includeDecimals: false, currency: 'USD' })}
      </span>
      &nbsp;
      <span className="text-black">{promoPrice}</span>
    </>
  )
}

function isPromotionOneTime(promotion: Stripe.PromotionCode) {
  if (!promotion) return false
  return (
    promotion.coupon.duration === 'once' ||
    (promotion.coupon.duration === 'repeating' && promotion.coupon.duration_in_months === 1)
  )
}

function isPromotionNTimes(promotion: Stripe.PromotionCode) {
  if (!promotion) return false
  return (
    promotion.coupon.duration === 'repeating' &&
    promotion.coupon.duration_in_months &&
    promotion.coupon.duration_in_months > 1
  )
}
